import { get } from 'lodash';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import {
  getHomepageBodyData,
  getHomepageHeaderData,
  // getLatestArticles,
} from 'services';

const Homepage = dynamic(() => import('page_components/home'));

export const getServerSideProps = async (context) => {
  const { locale } = context;
  const { req } = context;
  const headers = req.headers;
  try {
    const results = await Promise.all([
      getHomepageHeaderData(locale),
      // getLatestArticles(locale),
      getHomepageBodyData(),
    ]);

    return {
      props: {
        bannerData: get(results, '[0].data.data', []),
        // latestData: get(results, '[1].data.articles', []),
        bodyData: get(results, '[1].data.data.body', []),
        headers: headers,

      },
    };
  } catch (error) {
    console.log(error);
    return { props: {} };
  }
};

const Home = ({ bannerData, bodyData, headers}) => {
  useEffect(() => {
    console.log('Req.headers', headers);
  }, []);
  return (
    <Homepage
      bannerData={bannerData}
      // latestData={latestData}
      bodyData={bodyData}
    />
  );
};

export default Home;
